import React, {Fragment, useEffect, useState} from "react";
import css from "./apply.module.less";
import {Button, Checkbox, Descriptions, Form, message, Modal, Statistic, Table} from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import {TagAudit} from "../../../../components";
import {useDataApi} from "../../../../hook";
import {tap} from "rxjs/operators";

import {useHistory} from "react-router-dom";
import {AUDIT_SUPPLIER, AWAIT, OVER} from "../../../../common/static_number";
import {Contacts} from "../contacts";
import {PublishModal} from "../../../../common";

const { Countdown } = Statistic;
const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10},
};


/** 供应商报名列表 */
export const Apply = (props) => {
    const {form,params:{project_sn=''} } = props;
    const readOnly = false;
    const [params,setParams] = useState({});
    const [dataSource, setDataSource] = useState([]); //供应商列表
    const [users, setUsers] = useState([]); //选中的审批人
    const [contactsVisible, showContactsVisible] = useState(false); //审批人弹窗控制


    const [{data,setData}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_SUPPLIER,project_sn}});
    const [{data: supplierData}] = useDataApi({
        url: '/api/signList',
        method: 'post',
        data: {project_sn, sign_status: '2'},//1=开标结果列表 2=报名列表 3=报名结果 4= 投标信息列表2
        initialData: []
    });// 获取公告中已选的供应商
    const [{data:downTime,isLoading:timeLoading}] = useDataApi({url: '/api/downtime', method: 'post',data:{project_sn},initialData: {'signtimeres':0}});
    const [time,setTime] = useState(0);

    useEffect(() => {
        if(supplierData.list){
            const _data = supplierData.list.filter(item => {
                return {check: false, ...item}
            });
            setDataSource(_data);
        }
    }, [supplierData]);
    const onFinish = values => {
        const _data = data;
        _data.status = 37;
        setData({..._data});
    };
    useEffect(() => {
        if(!timeLoading&& downTime.signtimeres){
           setTime(Date.now() + downTime.signtimeres * 1000)
           // setTime(Date.now() + 5* 1000)
        }
    }, [timeLoading,downTime]);
    const col = [
        {
            title: '序号',
            width: 65,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>{index+1}</span>
                )
            }
        },
        {
            title: '供应商名称',
            align: 'center',
            dataIndex: 'store_name',
        },
        {
            title: '联系人',
            align: 'center',
            dataIndex: 'store_user_name',
        },
        {
            title: '联系方式',
            align: 'center',
            dataIndex: 'store_user_phone',

        },
        {
            title: '报名时间',
            align: 'center',
            dataIndex: 'createtime',
        },
        {
            title: '报名状态',
            align: 'center',
            dataIndex: 'status',
        },
        {
            title: '详情',
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <a onClick={()=>push({pathname:'/applyDetail',state:{project_sign_id:record.id}})}>资审</a>
                )
            }
        },
    ];

    const handleSubmit = ()=>{
        if(!params.content){
            message.error('您还没有填写资审信息哟~');
            return;
        }
        showContactsVisible(true)
    };

    const submit =()=>{

        const checkList = dataSource.map((item)=>{
            return {project_sign_id:item.id}
        })

        const audit = users[0];
        PublishModal.setResponsible({
            type: AUDIT_SUPPLIER, project_sn, is_approval:1, ...audit
        }).then(({data: {data, msg, code}}) => {
            if (code === 200) {
                PublishModal.signApproval({project_sn,signdata:checkList,appr_content:params.content}).pipe(
                    tap(res=>console.log(res))
                ).subscribe({
                    next:res=>{
                        message.success('提交成功',3, push({pathname:'/publish',state:{step:6,project_sn}}));
                    },
                    error:err=>{},
                    complete:()=>{}
                });
            } else {
                message.error(msg)
            }
        })
    };

    const excludeControls = ['media', 'emoji'];
    const handleEditorChange = (editor) => {
        let _params = params;
        _params.content = editor.toHTML();
        setParams(_params);
    };

    const {push} = useHistory();
    const goCommune = ()=>{
        push({pathname:'/publish/commune',state:{project_sn}});
    };

    /** 审批人弹窗选择完成 */
    const handleContactsOk = e => {
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        showContactsVisible(false);
        submit();
    };
    /** 人员选择回调函数 */
    const contactsSuccess = e => {
        setUsers(e.map(item => {
            return {username: item.title, user_id: item.id}
        }));
    };
    /** 关闭审批人弹窗 */
    const handleCancel = e => {
        showContactsVisible(false);
    };
    return (
        <div className={css.apply}>
            <div className={css.boxTitle}>
                供应商报名列表 <TagAudit data={{'30':{title:'报名中',index: AWAIT},'37':{title:'报名已结束',index: OVER}}[data.status]}/>
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>
            {
               <Form
                    {...layout}
                    initialValues={{ remember: true }}
                    scrollToFirstError = { true}
                    form={form}
                >
                    <Descriptions column={1}>
                        {/*<Descriptions.Item label="距报名截止时间还剩"></Descriptions.Item>*/}
                        <Descriptions.Item label="报名时间">{data.signup_time} ~ {data.signend_time}</Descriptions.Item>
                        <Descriptions.Item label="距报名截止" >
                            {
                                !timeLoading && (downTime.signtimeres ?   <Countdown value={time} format="D 天 H 时 m 分 s 秒"   onFinish={onFinish}/> :  '报名已结束')
                            }
                        </Descriptions.Item>
                       {/* <Descriptions.Item label="" style={{textAlign: 'right'}}>
                            <Button type={"primary"} style={{marginRight:'15px'}} onClick={()=>push({pathname:'/alteration',state: {project_sn,step:1}})}>变更招标文件</Button>
                            <Button type={"primary"}>变更记录</Button>
                        </Descriptions.Item>*/}
                    </Descriptions>
                    <Descriptions >
                        <Descriptions.Item label="供应商列表"/>
                        <Descriptions.Item label="" style={{textAlign: 'right'}}>
                            <Button type={"primary"} onClick={goCommune}>答疑</Button>
                        </Descriptions.Item>
                    </Descriptions>
                    <Table
                        bordered
                        pagination={false}
                        dataSource={dataSource}
                        columns={col}
                        style={{marginBottom:'20px'}}
                    />
                   {
                       (data.status === 37 || !downTime.signtimeres)  && <Fragment>
                           <Descriptions >
                               <Descriptions.Item label="资审报告信息" />
                           </Descriptions>
                           <BraftEditor
                               excludeControls={excludeControls}
                               readOnly={readOnly}
                               onChange={handleEditorChange}
                               className={css.editor}
                               contentStyle={{height:'200px'}}
                               controlBarStyle={{display: readOnly? 'none' :''}}
                           />
                       </Fragment>
                   }

                    <div className={css.btns}>
                        {
                            (data.status === 30 || data.status === 37 )&& <Button type="primary" size={"large"} onClick={ handleSubmit } disabled={data.status !== 37} >
                                提交审批
                            </Button>
                        }

                    </div>
                </Form>
            }

            <Modal
                title="选择审批人"
                visible={contactsVisible}
                onOk={handleContactsOk}
                onCancel={handleCancel}
            >
                <Contacts success={contactsSuccess} users={users}/>
            </Modal>
        </div>);
}
