import React from 'react';
import {Route, Switch} from "react-router-dom";
import css from './index.module.less';
import {SubNav} from "./components";
import {Home} from "./home";

/** 关于我们 */
export const About = (props) => {

    return (
        <div style={{background: '#F5F5F3'}}>
            <div className="container">
                <div className={css.about}>
                    <SubNav />
                    <Switch>
                        <Route exact from="/about/home" component={Home} />
                    </Switch>
                </div>
            </div>
        </div>
    );
}