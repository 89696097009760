import React from 'react';
import css from './footer.module.less';
import {useHistory} from "react-router-dom";
import {TYPE_PROCUREMENT} from "../common/static_number";
import {Modal} from 'antd';
import ga from 'aliasimgurl/ga.png';

export const Footer = ()=>{
    const {push} = useHistory();
    const handleContact = ()=>{
        Modal.success({
            title:'联系我们',
            content: '联系电话：0871-65819655',
            okText:'确定',
            maskClosable: true,
            onOk: ()=>{

            },
        });
    }
    return (
        <div className={ css.footer }>
            <div className="container">
                <div className={ css.footerList }>
                    <div className={ css.item }>
                        <h3>在线招标</h3>
                        <div className={ css.desc }>免费发布招标信息，免费查询项目信息。</div>
                        <div className={ css.btn }>
                            <a onClick={()=>{ push("/publish")}}>
                                <i className="iconfont iconpaimai" />
                                <span>欢迎在线招标</span>
                            </a>
                        </div>
                    </div>
                    <div className={ css.item }>
                        <h3>在线采购</h3>
                        <div className={ css.desc }>免费发布采购信息，免费查询项目信息 </div>
                        <div className={ css.btn }>
                            <a onClick={()=>{ push({pathname:"/publish",state:{type:TYPE_PROCUREMENT}})}}>
                                <i className="iconfont iconbianji" />
                                <span>欢迎在线发布</span>
                            </a>
                        </div>
                    </div>
                    <div className={ css.item }>
                        <h3>关于我们</h3>
                        <div className={ css.desc }>了解更多企业信息、相关资讯、文化及合作。</div>
                        <div className={ css.btn }>
                            <a onClick={()=>push("/about/home")}>
                                <i className="iconfont iconwomen" />
                                <span>欢迎走进我们</span>
                            </a>
                        </div>
                    </div>
                    <div className={ css.item }>
                        <h3>联系我们</h3>
                        <div className={ css.desc }>欢迎来电咨询相关业务，欢迎实地考察</div>
                        <div className={ css.btn }>
                            <a onClick={handleContact}>
                                <i className="iconfont icondianhua" />
                                <span>欢迎联系我们</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ css.footerBottom }>
                <div>
                    © 2022 版权所有 昆明农村产权流转服务平台  滇ICP备12003055号-2
                </div>
                {/*<div>增值电信业务经营许可证：京20204808号</div>*/}
                <div>
                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53011202001182"><img src={ga} /><span>滇公网安备 53011202001182号</span></ a>
                </div>
              {/*  <div>
                    运营服务：北京中镇生产力科技中心 中镇枫林规划设计研究院
                </div>
                <div>
                    技术支持：中镇生态科技河北有限公司
                </div>*/}
            </div>
        </div>
    );
}
