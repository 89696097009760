import React, {useState,useEffect} from 'react';
import {useEffectOnce, useToggle} from 'react-use';

import {CardBox, CommonList, DefaultList, UploadFile} from '../components';
import {HotProject, IndexFocus, NewsList, NoticesList, NoticesScroll, ProjectList,} from './components/home';
import classNames from 'classnames';
import css from './index.module.less';
import {ServicesModel} from '../common';
import {Button, Form, Input, message, Modal, Select} from 'antd';
import {useDataApi} from "../hook";
import {useHistory} from "react-router-dom";
import {createFromIconfontCN} from '@ant-design/icons';

import QRCode from 'aliasimgurl/QR_code.png';


const {TextArea} = Input;
const {Option} = Select;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_zqsgv2ip2wc.js',
});

export const Home = (props)=>{
    const [{data,isLoading }] = useDataApi({ url: '/api/index', method: 'post', initialData: {}});
    const {push} = useHistory();
    const [ideaForm] = Form.useForm();
    const [topFlag,setTopFlag] = useState(false);
    const ExtOne = <span><i onClick={()=>push({pathname:'/market',state:{infor_type:189}})}>招标公告</i><i onClick={()=>push({pathname:'/market',state:{infor_type:188}})}>变更公告</i><i onClick={()=>push({pathname:'/bidhall',state:{infor_type:187}})}>中标公示</i></span>
    // 建材装饰  |  医疗医药  |  通信设备 |  仪表仪器  |   机械设备   |  安防设备
    const ExtTwo = <span></span>;
    const [ideaFlag,setIdeaFlag] = useState('');

    const goList =(type)=>{
        // zh
        switch (type) {
            case 'zh':
                push({pathname:'/market'});
                break;
            case 'news':
                push({pathname: '/category/148/177'});
                break;
            case 'cg':
                 push({pathname:'/bidding'});
                break;
            case 'zb':
                push({pathname:'/market',state:{infor_type:187}});
                break;
            case 'bk':
                push('/baike?c_id=250');
                break;
            case 'download':
                push('/download?c_id=251');
                break;
            default:
        }

    }

    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
        return ()=> window.removeEventListener('scroll', handleScroll);
    },[]);
    const handleScroll = (e)=>{
        let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
        if(scrollTop>800){
            setTopFlag(true);
        }else{
            setTopFlag(false);
        }
    }
    const goDetail= (type,opt)=>{
        /* banners banner图 hotType 热门分类 hot 热门项目 area 地区 gd 滚动 zh 招标 cg 采购 news 新闻 zb 中标公示 bk 招标百科 download 下载 */
        switch (type) {
            case 'banner':
                /*todo 接轮播图跳转*/
                break;
            case 'proType':
                push({pathname:'/market',state:{industry_type:opt.id}});
                break;
            case 'area':
                push({pathname:'/market',state:{province_id:opt.id}});
                break;
            case 'hot':
                if(opt.ptype === 1){
                    push({pathname:'/market/view/'+opt.project_sn});
                }else{
                    push({pathname:'/bidding/view/'+opt.project_sn});
                }
                break;

            case 'zh':
                push({pathname:'/market/view/'+opt.project_sn});
                break;
            case 'gd':
                push({pathname: '/category/148/178/view/'+opt.id});
                break;
            case 'cg':
                push({pathname:'/bidding/view/'+opt.project_sn});
                break;
            case 'news':
                push({pathname: '/category/148/177/view/'+opt.id});
                break;
            case 'zb':
                push({pathname:'/bidhall/view/'+opt.project_sn});
                break;
            case 'bk':
                push({pathname:'/baike/view/250/'+opt.id});
                break;
            case 'download':
                push({pathname: '/download/view/251/'+ opt});
                break;
            default:
        }
    };
    const goPublish =()=>{
        push("/publish")
    };

    const goTop =()=>{
        let scrollToTop = window.setInterval(function() {
            let pos = window.pageYOffset;
            if ( pos > 0 ) {
                window.scrollTo( 0, pos - 20 ); // how far to scroll on each step
            } else {
                window.clearInterval( scrollToTop );
            }
        }, 2);
    };

    /** 意见反馈 */
    const saveIdea = (v)=>{

        ServicesModel.saveIdea(v).subscribe({
            next:res=>{
                message.success('提交成功',1,()=>{
                    setIdeaFlag(false);
                })
            }
        })
    };
    return (
        <div>
            {
                !isLoading &&   <div className="container">
                    <div className="container">
                        <IndexFocus banners={data.banners} area={data.area} protype={data.protype} goDetail={goDetail}/>
                    </div>
                    <div className="container">
                        <NoticesScroll items={data.gdlist} goDetail={e => goDetail('gd',e)}/>
                    </div>
                    <div className={ css.mainWithBar }>
                        <div className={ css.leftPart }>
                            <CardBox title="招标信息" ext={ ExtOne }  more={ ()=>goList('zh')}>
                                <NoticesList data={data} goDetail={e => goDetail('zh',e)}/>
                            </CardBox>
                        </div>
                        <div className={ css.sideBar }>
                            <CardBox title="新闻资讯" more={ ()=>goList('news')}>
                                <NewsList items={data.newslist} goDetail={e => goDetail('news',e)}/>
                            </CardBox>
                        </div>
                    </div>
                    <div className={ css.mainWithBar }>
                        <div className={ css.leftPart }>
                            <CardBox title="采购信息" ext={ ExtTwo }  more={ ()=>goList('cg') }>
                                <ProjectList items={data.cglist} goDetail={e => goDetail('cg',e)}/>
                            </CardBox>
                        </div>
                        <div className={ css.sideBar }>
                            <CardBox title="热门项目">
                                <HotProject items={data.hotlist} goDetail={e => goDetail('hot',e)}/>
                            </CardBox>
                        </div>
                    </div>
                    <div className={ classNames(css.mainWithBar) }>
                        <div className={ classNames(css.leftPart,css.lastDiv) } >
                            <CardBox title="中标公示" more={ ()=>goList('zb')}>
                                <CommonList items={data.zblist}  goDetail={ e => goDetail('zb',e)}/>
                            </CardBox>
                            <CardBox title="招标百科" more={ ()=>goList('bk')}>
                                <CommonList items={data.bklist}  goDetail={ e => goDetail('bk',e)}/>
                            </CardBox>
                        </div>
                        <div className={ css.sideBar }>
                            <CardBox title="下载专区" more={ ()=> goList('download')}>
                                <DefaultList items={data.xzlist} goDetail={ e=>goDetail('download',e)}/>
                            </CardBox>
                        </div>
                    </div>
                    <div className={css.rightMenu}>
                        <div className={css.menuBox}>
                            <div className={classNames(css.menuItem,css.send)} onClick={goPublish}>
                              <IconFont type={'icon-fabu'} className={css.icon}/>
                              <div>发布</div>
                            </div>
                            <div className={css.menuItem}>
                                <IconFont type={'icon-kefu'} className={css.icon}/>
                                <div>客服</div>
                                <div className={css.ext} style={{left: '-191px',textAlign: 'left',padding: '8px 15px'}}>
                                    <span>服务热线：0871-65819655</span> <br/>
                                    <span>工作时间：09:00--18:00</span>
                                </div>
                            </div>
                            <div className={css.menuItem}>
                                <IconFont type={'icon-erweima'} className={css.icon}/>
                                <div>关注</div>
                                <div className={css.ext} style={{left: '-80px',top: '-8px'}}>
                                    <img src={QRCode} className={css.QRcode} alt=""/>
                                </div>
                            </div>
                            <div className={css.menuItem} onClick={()=> setIdeaFlag(true)}>
                                <IconFont type={'icon-bianji'} className={css.icon}/>
                                <div>反馈</div>
                            </div>
                        </div>
                        {
                            topFlag &&  <div className={css.menuBox} style={{marginTop: '5px'}}>
                                <div className={css.menuItem}  onClick={goTop}>
                                    <IconFont type={'icon-fanhuidingbu-'} className={css.icon}/>
                                    <div>回顶部</div>
                                </div>
                            </div>
                        }


                    </div>
                </div>
            }
            <Modal  title="意见反馈"
                    visible={ ideaFlag }
                    centered
                    destroyOnClose
                    footer={null}
                    okText={'提交'}
                    onCancel={()=>setIdeaFlag(false)}
            >
                <Form form={ideaForm} onFinish={saveIdea} labelCol={{span:4}}>
                    <Form.Item name={'type'} label={'反馈类型'} wrapperCol={{span:10}} rules={[{ required: true, message: '请选择反馈类型' }]} >
                        <Select placeholder="请选择反馈类型">
                            <Option value={'1'}>优化建议</Option>
                            <Option value={'2'}>页面报错</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="邮箱"
                        name="email"
                        wrapperCol={{span:10}}

                        rules={[{
                            type: 'email',
                            message: '邮箱格式不正确',
                        },{required: true, message: '请输入邮箱' }]}
                    >
                        <Input autoComplete="off" placeholder="请输入联系邮箱"/>
                    </Form.Item>
                    <Form.Item name={'content'} label={'详细描述'}  rules={[{ required: true, message: '请输入详细描述' }]}>
                        <TextArea autoSize rows={4} placeholder={'请输入详细描述'}/>
                    </Form.Item>
                    <Form.Item label={'上传图片'} name={'file'}>
                        <UploadFile uploadNum={5} accept={'.jpg, .jpeg, .png, .gif'}/>
                    </Form.Item>
                    <div style={{textAlign: 'center'}}>
                        <Button type={"primary"} htmlType={'submit'}>提交反馈</Button>

                    </div>
                </Form>
            </Modal>
        </div>
    );
}