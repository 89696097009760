import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
import {TypeAndRegin} from './index';
import {AppstoreOutlined, FormOutlined, SettingOutlined, SoundOutlined} from '@ant-design/icons';
import css from './index-focus.module.less';
import {useIsLogin} from "../../../hook";
import {useHistory} from "react-router-dom";
import {PROJECT_URL, EXPERT_URL} from "../../../common/static_number";


export const IndexFocus =(props)=>{

    const {banners=[],protype=[],area=[],goDetail} = props;
    const [isLogin] = useIsLogin();
    const {push} = useHistory();

    const onError = (e)=>{
        console.log(e);

    }
    const [swiper, updateSwiper] = useState(null);
    const params = {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        getSwiper: updateSwiper
    }

    const handleMouseOver = ()=>{
        swiper.autoplay.stop()
    };
    const handleMouseOut = ()=>{
        swiper.autoplay.start()
    };
    return (
        <div>
        <div className={ css.list } >
            {banners.length && <Swiper { ...params } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                {
                    banners.map((opt,index)=>
                        <div className={ css.item } key={ index } onClick={()=>goDetail('banner',opt)}>
                            <img style={ {fontSize:0,display:'block'} }
                                 onError={ (e)=>onError(e) } src={ opt.images } alt="图片"/>
                        </div>
                    )
                }
            </Swiper>
            }
            <div className={ css['type-and-regin'] }>
                <TypeAndRegin protype={protype} area={area} goDetail={goDetail}/>
            </div>
            <div className={ css.loginForm }>
                        <div className={css.loginInfo}>
                            <div className={css.content}>
                                <div className={css.item} onClick={()=>push(isLogin ? '/publish':'/login')}>
                                    <FormOutlined style={{fontSize:"24px",marginBottom:'5px'}}/> <br/>
                                    <span>项目发布</span>
                                </div>
                                <div className={css.item} onClick={()=>push(isLogin ? '/account/project/list':'/login')}>
                                    <AppstoreOutlined style={{fontSize:"24px",marginBottom:'5px'}}/> <br/>
                                    <span>项目管理</span>
                                </div>
                                <div className={css.item} onClick={()=>push(isLogin ? '/account/work':'/login')}>
                                    <SoundOutlined style={{fontSize:"24px",marginBottom:'5px'}}/> <br/>
                                    <span>消息通知</span>
                                </div>
                                <div className={css.item} onClick={()=>push(isLogin ?'/account/setting':'/login')}>
                                    <SettingOutlined style={{fontSize:"24px",marginBottom:'5px'}}/> <br/>
                                    <span>账户设置</span>
                                </div>
                            </div>

                            <div className={css.mine}>
                                {/*<a href={isLogin ? "/account/setting": '/login'} className={css.mine}>*/}
                                {/*    进入我的账户*/}
                                {/*</a>*/}
                                <a href={EXPERT_URL} target={'_blank'}>
                                    评标专家入口
                                </a>
                                <a href={PROJECT_URL} target={'_blank'}>
                                    百大项目入口
                                </a>
                            </div>

                        </div>
                </div>
        </div>
        </div>

    );
}